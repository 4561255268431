import React, {useState} from 'react';
import {
	ChakraProvider,
	theme,
	Alert,
	AlertIcon,
	Card,
	CardBody,
	Input,
	InputGroup,
	InputRightElement,
	Button,
	Progress,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	UnorderedList,
	ListItem
} from '@chakra-ui/react';
import Logo from './logo.png';

function App() {

	const [search, setSearch] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const [showResultsModal, setShowResultsModal] = useState(false);
	const [showNoResultsAlert, setShowNoResultsAlert] = useState(false);
	const [showDetailModal, setShowDetailModal] = useState(false);
	const [detailModalData, setDetailModalData] = useState({});

	const [results, setResults] = useState([]);

	const doSearch = () => {
		setShowNoResultsAlert(false);
		setShowResultsModal(false);
		setShowDetailModal(false);
		setIsLoading(true);
		

		fetch('/api?action=search&searchTerm=' + search)
			.then(response => response.json())
			.then(data => {
				setIsLoading(false);
				if (data.result === "ERR_NO_RESULTS") {
					setShowNoResultsAlert(true);
				} else if (data.result === "OK") {
					setResults(data.products);
					if (data.count > 1) {
						setShowResultsModal(true);
					} else {
						setDetailModalData(data.products[0]);
						setShowDetailModal(true);
					}
				}
			})
			.catch(error => {
				setIsLoading(false);
				console.error(error);
			});


	}

	return (
		<ChakraProvider theme={theme}>
			<Card>
				<CardBody>
					
					<img src={Logo} className="Card-Logo" alt="AZ Werbung Zusatzstoff-Datenbank" /><br />

					{showNoResultsAlert ? (
						<Alert status='warning'>
							<AlertIcon />
							Keine Ergebnisse gefunden. Bitte versuche es mit anderen Suchbegriffen.
						</Alert>
					) : (
						<Alert status='info'>
							<AlertIcon />
							Herzlich Willkommen in der AZ Werbung Zusatzstoff-Datenbank
						</Alert>
					)}

					<div style={{
						opacity: isLoading ? 1 : 0,
						transition: 'opacity 0.5s ease-in-out',
						borderRadius: '0',
						overflow: 'hidden',
						padding: '15px 0'
					}}>
						<Progress style={{borderRadius:'1000px'}} size='xs' isIndeterminate />
					</div>

					<InputGroup size='md'>
						<Input
							pr='85px'
							placeholder='Suche nach Speisen & Getränken'
							value={search}
							onChange={(e) => {setSearch(e.target.value);setShowNoResultsAlert(false)}}
							onKeyUp={(e) => e.key === 'Enter' && doSearch()}
							disabled={isLoading}
						/>
						<InputRightElement width='85px'>
							<Button
								h='1.75rem'
								size='sm'
								isLoading={isLoading}
								onClick={doSearch}
							>Suchen</Button>
						</InputRightElement>
					</InputGroup>

				</CardBody>
			</Card>


			<Modal isOpen={showResultsModal} onClose={() => {setShowResultsModal(false)}}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Suchergebnis</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						
						<div className='Results-List'>

							{results?.map((result, index) => (
								<div key={index} className="Results-List-Item" onClick={() => {
									setDetailModalData(result)
									setShowDetailModal(true)
								}}>
									<small>{result.category_name}</small><br />
									<span>{result.product_name}</span>
								</div>
							))}

						</div>

					</ModalBody>

					<ModalFooter>
						<Button colorScheme='blue' onClick={() => {setShowResultsModal(false)}}>Schließen</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>


			<Modal isOpen={showDetailModal} size={"sm"} onClose={() => {setShowDetailModal(false)}}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>{detailModalData?.product_name || "Ergebnis lädt..."}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>

						<p>{detailModalData?.product_description || "Beschreibung lädt..."}</p><br />
						
						<UnorderedList>
							{detailModalData?.product_allergens?.map((additive, index) => (
								<ListItem key={index}>{additive}</ListItem>
							))}
						</UnorderedList>

					</ModalBody>

					<ModalFooter>
						<Button colorScheme='blue' onClick={() => {setShowDetailModal(false)}}>Schließen</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>


		</ChakraProvider>
	);
}

export default App;
